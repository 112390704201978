<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
            <!-- 期间限定 -->
      <a style="display: block;" target="_blank" href="https://maidocoin.com/entrance/?lang=zh-tw">
        <el-image :src="qjxdImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>

      <!-- 积分 -->
      <a id="stepImgId" style="display: block;" target="_blank" href="https://tripellet.com/tcapp-download-b/tc">
        <el-image :src="stepImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>
      <!-- 设施一览表 -->
      <div style="background-color: #f3d2c3;">
        <el-image :src="modelcourse">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
        </el-image>
        <el-image :src="modelcourse2">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
        <el-image :src="placeholder_title">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </div>
      <!-- 使用方法 -->
      <!-- <div class="usage_box">
        <div class="title">暢遊關西必備的景點套票，精選大阪、神戶、京都多個人氣設施與美食店家，一週內可以任選三個或六個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
            ●同設施只可以使用一次，相同設施不可再入場<br>
            ●票劵使用效期為購買日起<span>90天內</span><br>
             EX:<span>7/1</span>購買票券⇒<span>票期:7/1~9/28</span><br>
            ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
            EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
            ●有效期間內可任<span>選3項</span>或<span>6項</span>設施<br>
            ●注意: 單次購買複數票劵時，當其中一張票劵開始使用，所有票劵
            也將同步開通。(<span>使用效期7天</span>)<br>
            ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
             EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
            ●無使用完畢不能兌現<br>
            ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div> -->
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>

        <div class="xiao_btn_img" v-if="item.iconImgBr">
          <a v-if="item.iconImgBr1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.iconImgBr2" href="#stepImgId"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.iconImgBr3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a class="dltAbq" :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfkansai/zh_tw/havefun_kansai_tc.png'),
      qjxdImg: require('@/assets/images/hfkansai/zh_tw/qjxd_banner_tc.png'),
      stepImg: require('@/assets/images/hfkansai/zh_tw/4step_tc.png'),
      modelcourse: require('@/assets/images/hfkansai/zh_tw/havefun_modelcourse_1_tc.jpg'),
      modelcourse2: require('@/assets/images/hfkansai/zh_tw/havefun_modelcourse_2_tc.jpg'),
      placeholder_title: require('@/assets/images/hfkansai/zh_tw/placeholder_title_TC.png'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      xiao_btn_img1: require('@/assets/images/hfkansai/zh_tw/play_TC.png'),
      xiao_btn_img2: require('@/assets/images/hfkansai/jifen.png'),
      xiao_btn_img3: require('@/assets/images/hfkansai/zh_tw/qjxd2_TC_JP.png'),
      dataList: [
        // {
        //   id: 1,
        //   img: require('@/assets/images/hfkansai/zh/グループ 264.png'),
        //   title: ['阿倍野HARUKAS 300 展望台'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兌換地點',
        //       content: [{ cid: 1, text: ['大阪市阿倍野區阿倍野筋1-1-43'] }, { cid: 2, text: ['(HARUKAS300展望台16樓)'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方網站',
        //       content: [{ cid: 1, text: ['https://www.abenoharukas-300.jp/tc/observatory/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事項',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['僅限使用一次，退場後無法再次入場，請知曉。'] },
        //         { cid: 2, text: ['使用當天前往16層服務台掃碼入場。'] }
        //       ]
        //     }]
        // },
        {
          id: 2,
          img: require('@/assets/images/hfkansai/kintetsu_tc2.jpg'),
          title: ['近鐵百貨經典紀念套組兌換券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['大阪市阿倍野區阿倍野筋1－1－43'] }, { cid: 2, text: ['翼館3.5樓 國際貴賓沙龍 Foreign Customer’s Salon'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://abenoharukas.d-kintetsu.co.jp/special/foreign/index-ct.html'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以兌換近鐵百貨經典紀念套組。','（今治高級毛巾&近鐵百貨店吉祥物資料夾1入套組）'] },
                { cid: 2, text: ['套組商品恕不退換，敬請知悉見諒。'] },
                { cid: 3, text: ['本活動僅限近鐵百貨海闊天空總店適用。'] },
                { cid: 4, text: ['圖片僅供參考,實品請以實物爲主。'] }
              ]
            }]
        },
        
        {
          id: 3,
          img: require('@/assets/images/hfkansai/zh/グループ 263.png'),
          title: ['大阪觀光周遊巴士Wonder Loop'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['大阪市中央區道頓崛1丁目16 沿著河邊B1', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://wonderloop.jp/zh-hant/wonder-loop/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請繫安全帶，並且請勿站立、移動變換座位。'] },
                { cid: 2, text: ['有活動、交通限制或引導等時，可能會停運或變更路線的情形發生。'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfkansai/zh/グループ 262.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/oXH-_2qjNlU',
          title: ['大阪道頓堀觀光遊艇 Wonder Cruise'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['大阪市中央區道頓崛1丁目16 沿著河邊B1', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://wondercruise.jp/doton/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行日程請在官方網站上確認。'] },
                { cid: 2, text: ['有時會因強風、降雨等氣象條件而停止運行。'] },
                { cid: 3, text: ['滿座的話可能會無法乘船。'] },
                { cid: 4, text: ['週一至週三只開放給有預約的旅客搭乘。'] }
              ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfkansai/zh/グループ 261.png'),
          title: ['大阪散步美食券(2張)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['●大阪市中央區道頓崛1丁目16 沿著河邊B1', '(Dotonbori Riverside Grill Wonder)'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['http://gourmet-walk.com/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請至兌換所出示票券，可獲得2張餐券。'] },
                { cid: 2, text: ['餐券請交由店家處理，勿自行撕下。'] },
                { cid: 3, text: ['無法任意變更散步美食家合作餐點。'] },
                { cid: 4, text: ['剩下的餐券無法再換回現金,敬請知悉見諒。'] },
                { cid: 5, text: ['兌換所的營業時間請事先至官網確認。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfkansai/298_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/TGv70R5L37k',
          title: ['298燒肉 1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '●298難波店(目前暫停營業)',
                '大阪府大阪市中央區千日前1-7-9 4F',
                '●298心齋橋店',
                '大阪府大阪市中央區心斎橋筋1-2-8',
                '●298梅田3號店',
                '大阪府大阪市北區堂山町5-8',
                '●298三宮店<span style="color:#FBB03B;">(神户)</span>',
                '神戸市中央區北長狹通1-4-2 B1',
                '●298福島店',
                '大阪府大阪市福島區福島6-1-39'
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['298難波店'],noColor:true },
                { cid: 2, text: ['http://www.ajibil.com/shop/298N.html'] },
                { cid: 3, text: ['298心齋橋店'],noColor:true },
                { cid: 4, text: ['https://298shinsaibashi.owst.jp/zh-tw/'] },
                { cid: 5, text: ['298梅田3號店'],noColor:true },
                { cid: 6, text: ['https://ajibiru-kashin-umeda.owst.jp/zh-tw/'] },
                { cid: 7, text: [`298三宫店<span style="color:#FBB03B;">(神户)</span>`],noColor:true },
                { cid: 8, text: ['http://www.ajibil.com/shop/298S.html'] },
                { cid: 9, text: [`298福島店`],noColor:true },
                { cid: 10, text: ['https://r.gnavi.co.jp/jm0fdd5d0000/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['298三宮店位於神戶。'] },
                { cid: 4, text: ['1000日元優惠券可從以下5家店鋪中任選一家做使用：','298難波店、298心齋橋店、298梅田3號店、298三宮店、298福島店。'] },
                { cid: 5, text: ['有可能會因爲店家客滿而無法使用的狀況，請見諒。'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfkansai/nonotori_CH.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/wg6_QwDLejA',
          title: ['野乃鳥 1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '●野乃鳥なんば堂',
                '大阪市中央區難波 3-7-19 GEMS難波8F',
                '●KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(神户)</span>',
                '神戶市中央區北長狹通1-1-1 EKIZO神戸三宮',
                '●野乃鳥 梅味堂',
                '大阪市北區中崎西3-3-15 JR高架下34'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['野乃鳥なんば堂'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/nambado/'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(神户)</span>'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/kobe-yakitori-stand/'] },
                { cid: 5, text: ['野乃鳥 梅味堂'],noColor:true },
                { cid: 6, text: ['https://nonotory.jp/stores/umemido/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥位於神戶。'] },
                { cid: 4, text: ['1000日元優惠券可從以下3家店鋪中任選一家做使用。野乃鳥なんば堂、KOBE YAKITORI STAND 野乃鳥、野乃鳥 梅味堂。'] },
                { cid: 5, text: ['入店時需額外支付小菜費用。'] },
                { cid: 6, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                { cid: 7, text: ['結帳時出示Travel Contents APP掃碼就可享有10%點數回饋。 APP下載連結：','<br><a style="color: rgb(33, 157, 204);border-bottom: 1px solid;" href="http://onelink.to/xyx76x">http://onelink.to/xyx76x</a>'] }
                ]
            }
          ]
        },
        {
          id: 46,
          img: require('@/assets/images/hfkansai/zh_tw/heniuunou_TC.png'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['和牛燒肉Unou 1000日圓優惠券 ','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['別館：大阪府大阪市西成區玉出中2-15-22'] },
                { cid: 2, text: ['都島本通店：大阪府大阪市都島區都島本通3-27-7'] },
                { cid: 3, text: ['福島本店：大阪府大阪市福島區福島2-7-7'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['別館：無官網'],noColor:true },
                { cid: 2, text: ['都島本通店：'],noColor:true },
                { cid: 3, text: ['https://www.hotpepper.jp/strJ000716500/'] },
                { cid: 4, text: ['https://instagram.com/unou_gram?igshid=YzAwZjE1ZTI0Zg=='] },
                { cid: 5, text: ['福島本店：'],noColor:true },
                { cid: 6, text: ['https://unoufukushima.owst.jp/'] },
                { cid: 7, text: ['https://www.instagram.com/unou_7319/?hl=ja'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['若有價差需現場支付差額。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                { cid: 4, text: ['一間店鋪限使用一次。','<br>例:已在別館使用過了，但都島本通店仍可使用。'] },
                { cid: 5, text: ['繁忙時段限時90分鐘。'] },
              ]
            }
          ]
        },
        {
          id: 47,
          img: require('@/assets/images/hfkansai/zh_tw/Nanjamonja_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Nanjamonja 1000日圓優惠券','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['大阪府大阪市中央區東心齋橋1-13-19 NT大樓2F'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://gurunavi.com/zh-hant/k342800/rst/'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['使用1000日圓優惠券享用大阪著名的御好燒。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
              ]
            }
          ]
        },
        {
          id: 48,
          img: require('@/assets/images/hfkansai/zh_tw/95jiaoshi_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['大阪三線教室 1小時體驗(需事先預約)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['大阪府大阪市生野區巽中4-4-5'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://500en-sanshin-school.jimdosite.com/'] },
                { cid: 2, text: ['(預約網站)'],noColor:true },
                { cid: 3, text: ['https://airrsv.net/sansin/calendar'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['產品內容:1小時體驗費'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
              ]
            }
          ]
        },
        {
          id: 49,
          img: require('@/assets/images/hfkansai/zh_tw/96jas_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['宗教法人 久安寺 坐禪、抄經、畫佛體驗(需事先預約)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['大阪府池田市伏尾町697'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://kyuanji.jp/'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['產品內容：坐禪、抄經、畫佛體驗。 三者擇一，包含入場費300日元。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
              ]
            }
          ]
        },
        {
          id: 50,
          img: require('@/assets/images/hfkansai/zh_tw/97rwgj_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['染屋貴久 染色體驗 1000日圓優惠券(需事先預約)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['大阪府池田市榮町6-17'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://someyakikyu.com/'] },
                { cid: 2, text: ['(預約網站)'],noColor:true },
                { cid: 3, text: ['https://kikyu.urkt.in/ja/direct/offices/65/courses'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
              ]
            }
          ]
        },
        {
          id: 51,
          img: require('@/assets/images/hfkansai/zh_tw/98bbdc_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['日本殺陣道協會 本部道場 體驗1000日圓優惠券(需事先預約)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['大阪府大阪市西區北堀江1丁目1-27 (3F)'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://nihontatedokyokai.com/'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
                { cid: 4, text: ['需提前預約。'] },
              ]
            }
          ]
        },
        {
          id: 52,
          img: require('@/assets/images/hfkansai/zh_tw/Kawaii_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['Kawaii Osaka KIMONO SHOP 和服購買/和服體驗 1000日圓優惠券','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['大阪府大阪市中央區宗右衛門町1-9'] },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://kawaii-osaka.jp/'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。(和服購買/和服體驗)'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
                { cid: 4, text: ['可能因預約狀況等因素無法提供順暢的服務，建議透過網站進行線上預訂。'] },
              ]
            }
          ]
        },
        {
          id: 17,
          img: require('@/assets/images/hfkansai/zh/SantaMaria_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/FVCxk3PCAA8',
          title: ['聖瑪麗亞號白天遊覧 乘船券','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒552-0022 大阪府大阪市港區海岸通1丁目1-10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://suijo-bus.osaka/language/santamaria/'] }
                ]
            },
            {
     
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行日程請在官方網站上確認。'] },
                { cid: 2, text: ['有時會因強風、降雨等氣象條件而停止運行。'] },
                { cid: 3, text: ['滿座的話可能會無法乘船。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 18,
          img: require('@/assets/images/hfkansai/zh/Paragliding_tc.png'),
          title: ['滑翔傘單人體驗 1000日圓優惠券','<span>(2023年7月~8月無法體驗)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '大阪府大阪市此花區北港綠地2-1-44(舞洲體育島)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/paraglider/index.html'] },
                { cid: 2, text: ['預約網址(僅日文)'],noColor:true  },
                { cid: 3, text: ['https://osakamachipara.com/'] },
                ]
            },
            {
     
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['全預約制營業，請事先預約。沒有事先預約的話，可能會有休業的情況。請務必確認後再使用。'] },
                { cid: 2, text: ['完全預約制,於官網預約(2/20起開放)。'] },
                { cid: 3, text: ['請攜帶方便運動的衣服和鞋子。'] },
                { cid: 4, text: ['其餘事項請參考當天的流程表或體驗報名表。'] },
                { cid: 5, text: ['體驗限制(年齡:3歲以上/身高:190cm以下/體重:80kg以下)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 19,
          img: require('@/assets/images/hfkansai/zh/SUPExperience_tc.png'),
          title: ['水上散步 SUP體驗 1000日圓優惠券','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒554-0042大阪府大阪市此花區北港綠地1丁目2-25'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/mishima-citysup.html'] },
                ]
            },
            {
     
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['圓形SUP/BIGSUP 中學生以下無法單獨體驗。'] },
                { cid: 2, text: ['自行車SUP有身高限制。'] },
                { cid: 3, text: ['飲酒者/孕婦無法體驗。'] },
                { cid: 4, text: ['可能因天候等因素取消。'] },
                { cid: 5, text: ['請穿著濕了也沒關係的衣服。<br>(有被水花或落船弄濕的可能性。)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 20,
          img: require('@/assets/images/hfkansai/zh/KeihanRailwa_tc.png'),
          title: ['京阪電車 京都大阪 觀光1日券 ','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [
                { cid: 1, text: ['京阪電車（淀屋橋站）'],noColor:true },
                { cid: 2, text: ['大阪市中央區北濱３丁目1－25（指定席票窗口）','<br>'],noColor:true },
                { cid: 3, text: ['京阪電車 (北濱站)'],noColor:true },
                { cid: 4, text: ['大阪府大阪市中央區北濱１丁目８－１６（指定席票窗口）','<br>'],noColor:true },
                { cid: 5, text: ['京阪電車（天滿橋站）'],noColor:true },
                { cid: 6, text: ['大阪府大阪市中央區谷町１丁目１（車站票務所）','<br>'],noColor:true },
                { cid: 7, text: ['京阪電車 (京橋站)'],noColor:true },
                { cid: 8, text: ['大阪府大阪市都島區東野田町２丁目１−３８（指定席票務窗口）','<br>'],noColor:true },
                { cid: 9, text: ['京阪電車 (三條站)'],noColor:true },
                { cid: 10, text: ['京都府京都市東山區大橋町 三條大橋東詰（指定席券窗口）'],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.keihan.co.jp/travel/cn/trains/passes-for-visitors-to-japan/kyoto-osaka.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['乘車前必須兌換為實體乘車券。'] },
                { cid: 2, text: ['兌換後實體票券的有效期限以票面後印刷的期限為準。'] },
                { cid: 3, text: ['本票券可乘坐「普通」「區間急行」「準急」「急行」「快速急行」「特急」「快速特急」電車。不可直接用於乘坐「premium car」(同一輛列車中的指定席車廂)。'] },
                { cid: 4, text: ['乘坐「premium car」列車需在車站另行購買指定席票券'] },
                ]
            }
          ]
        },
        // 
        {
          id: 21,
          img: require('@/assets/images/hfkansai/zh/CarThemePavilion_tc.png'),
          title: ['日本頭文字D跑車主題館代金券','(價值5500日元)','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒551-0031 大阪府大阪市大正區泉尾4丁目12-21'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://mrhiro-jdm.com/'] },
                { cid: 2, text: ['官網預約'],noColor:true },
                { cid: 3, text: ['https://mrhiro-jdm.com/rental'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['內容包含: ','<br>①日本頭文字D跑車租賃優惠2000日元','<br>②專業賽車模擬器30分以上體驗優惠2000日元','<br>③電動漂移車體驗20分以上體驗優惠1500日元'] },
                { cid: 2, text: ['一張票，多人分別體驗也可以，','<br>例如: 大人玩賽車模擬器，小孩可以玩漂移車。'] },
                { cid: 3, text: ['僅限當天體驗。'] },
                { cid: 4, text: ['頭文字D跑車租賃建議提前在官網預約。'] },
                { cid: 5, text: ['有可能會因為客滿而無法使用的狀況，請見諒。'] },
                { cid: 6, text: ['跑車租賃體驗須提供駕照，請提前於官網確認相關訊息。'] },
                ]
            }
          ]
        },
        {
          id: 22,
          img: require('@/assets/images/hfkansai/zh/terminal_tc.png'),
          title: ['大阪府「16美食街」 1300日元份餐券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-0001 大阪市北區梅田3-1-3 JR大阪站5樓 時空廣場 「BAR del SOLE」','<span style="color:red;">※「BAR del SOLE」7/18（二）～21（五）暫停營業，期間無法兌換餐券'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://osakastationcity.com/16gourmet/'] },
                { cid: 2, text: ['https://osakastationcity.com/pdf/ichiroku_inbound.pdf'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['換取可在對象店舖使用之餐券1000日元分x1、300日元分x1，一共2張。'] },
                { cid: 2, text: [
                  '此餐券可在下列店舖中當作現金券使用。',
                  '<br>・大阪車站南門大樓16樓「16美食街」「宮崎SHABUSHABU霧峰」「A・ran」「大阪竹葉亭」「uwo佐」「銀座禿頭天」「洋面屋五右衛門」「沙龍卵與我」「ST-MARC CAFE」「cafe 英國屋」「肉之匠 永田屋」「弦」「Hakkakuan」「PIER 30 GRILL」「中國料理 羅德斯」',
                  '<br>・大阪車站南門大樓1樓 「Cafe de Clever」',
                  '<br>・大阪車站南門大樓地下1樓 「San Marco」',
                  '<br>・時空廣場（大阪站5樓）「BAR del SOLE」'
                  ] },
                { cid: 3, text: ['此餐券一次結帳可使用多張。'] },
                { cid: 4, text: ['此餐券不可換成現金，也不可找零。'] },
                { cid: 5, text: ['此餐券由所使用之店舖收回。'] },
                { cid: 6, text: ['此餐券在大丸梅田店、大阪格蘭比亞酒店、LUCUA 大阪、Eki Marché Osaka、大阪車站城電影院等場所中無法使用。'] },
                { cid: 7, text: ['可使用之店舖有可能變更。'] },
                { cid: 8, text: ['營業時間有可能無預告變更。另外，若是因為臨時停業、客滿等情況無法使用餐券也無法退款。'] },
                { cid: 9, text: ['一旦兌換餐券後，無法取消、退款。'] },
                { cid: 10, text: ['不能直接出示二維碼使用。請務必先兌換成餐券後至各店舖使用。'] },
                { cid: 11, text: ['無法使用于對象店舖之外的地方。'] },
                ]
            }
          ]
        },
        {
          id: 23,
          img: require('@/assets/images/hfkansai/zh/meitian_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/ezYPmNdS_no',
          title: ['大阪梅田空中庭園展望台入場卷','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒531-6039 大阪市北區大澱中1-1-88 梅田藍天大廈 (39樓售票櫃台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.skybldg.co.jp/tw/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['大阪澱川煙花大會(2023/8/5)、跨年等特別營業日不可使用。'] },
                { cid: 2, text: ['使用輪椅的遊客請在東塔1樓乘坐高樓層專用電梯。'] },
                { cid: 3, text: ['暴風雨等天氣時，有可能無法進入樓頂，敬請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 24,
          img: require('@/assets/images/hfkansai/zh/CHOICE_tc.png'),
          title: ['BAR CHOICE　1000日元優惠券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒542-0083  中央區東心斎橋1-8-24　米山大樓１F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://choice-cola.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒'] },
                ]
            }
          ]
        },
        {
          id: 25,
          img: require('@/assets/images/hfkansai/zh/Wonder_tc.png'),
          title: ['Dotonbori Grill & Bar Wonder 1000円優惠券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒542-0071 大阪市中央区道頓堀1-1-6 川沿B1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://wonderpub.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒'] },
                { cid: 5, text: ['內有販售含酒精飲料，須年滿20歲才可以購買'] },
                ]
            }
          ]
        },
        {
          id: 26,
          img: require('@/assets/images/hfkansai/zh/chisozanmai_tc.png'),
          title: ['大丸松坂屋百貨店 馳走三昧 大丸梅田店 1000円優惠券','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-8202 大阪市北區梅田3-1-1 大丸 大阪・梅田店14F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.daimaru.co.jp.t.md.hp.transer.com/umedamise/restaurant/chisozanmai.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。(僅適用於每人消費5000日元以上）'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 28,
          img: require('@/assets/images/hfkansai/zh/LUCUAosaka_tc.jpg'),
          title: ['大阪站直通的購物中心「LUCUA osaka」 1000日元份館內共通商品券','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                'LUCUA 1100 2樓 服務中心','<span style="color:red;">※受理至2023年12月31日為止</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.lucua.jp/zh/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['內容包含: 1000日元分優惠券1張'] },
                { cid: 2, text: ['有效期限：','在2023年8月31日前兌換的商品券，有限期限到2023年8月31日。','在2023年9月1日後兌換的商品券，有限期限到2024年1月31日。'] },
                { cid: 3, text: ['此商品券不可換成現金，也不可購買其他商品券、郵票、明信片等商品。'] },
                { cid: 4, text: ['此商品券不可找零。'] },
                { cid: 5, text: ['此商品券在LUCUA內的一部分店舖中無法使用．（不可使用之店舖：LUCUA 5F「C-pla」、LUCUA 9F「FUJIMOTO眼科」、LUCUA 1100（Ihre） B1F「麥當勞」）另外，目前展示的店舖圖為2023年3月的情況，有可能變更店舖。'] },
                { cid: 6, text: ['營業時間有可能無預告變更。另外，若是因為臨時停業、客滿等情況無法使用商品券也無法退款。'] },
                ]
            }
          ]
        },
        {
          id: 30,
          img: require('@/assets/images/hfkansai/zh/EKI_MARCHE_tc.jpg'),
          title: ['大阪站內購物中心「EKI MARCHÉ大阪」館內共通券 1000日元份','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '「EKI MARCHÉ大阪」中的服務中心',
                '<span style="color:red;">※受理至2023年12月31日為止</span>',
                '<span style="color:red;">※預訂日起90天內，電子票可兌換優惠券。</span>',
                '<span style="color:red;">※休館日5/9（二）、9/5（二）無法兌換、使用優惠券。</span>'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://osaka.ekimaru.com/oc'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['內容包含: 500日元份優惠券2張'] },
                { cid: 2, text: ['實體優惠券的有效期是電子憑證兌換的下個月末截止。（例：若於3/15日兌換成實體券，實體券有效期限為4/30）'] },
                { cid: 3, text: ['此優惠券一次結帳可使用多張。'] },
                { cid: 4, text: ['此優惠券不可換成現金，也不可找零。'] },
                { cid: 5, text: ['可使用之店舖有可能變更。營業時間有可能無預告變更。另外，若是因為臨時停業、客滿等情況無法使用優惠券也無法退款。'] },
                { cid: 6, text: ['一旦兌換優惠券後，無法取消、退款。'] },
                { cid: 7, text: ['不能直接出示二維碼使用。請務必先兌換成優惠券後至各店舖使用。'] },
                { cid: 8, text: ['無法使用于對象店舖之外的地方。（EKI MARCHÉ內無法使用的店舖：JTB、銀行ATM、DreamShop）'] },
                { cid: 9, text: ['總共500人份，兌換完为止。'] },
                ]
            }
          ]
        },
        {
          id: 31,
          img: require('@/assets/images/hfkansai/zh/Yamachan_tc.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/dbJ6ER5g6GM',
          title: ['大阪梅田章魚燒市場 Yamachan HEP FIVE店 1000日元優惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北區角田町５−１５ HEP Five １樓'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 32,
          img: require('@/assets/images/hfkansai/zh/HEP_NAVIO_tc.jpg'),
          title: ['大阪梅田章魚燒市場 章魚燒十八番 HEP NAVIO店 1000日元優惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北區角田町７－１０ HEP Five １樓'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 33,
          img: require('@/assets/images/hfkansai/zh/Yashiki_tc.jpg'),
          title: ['大阪梅田章魚燒市場 甲賀流 HEP NAVIO店 1000日元優惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北區角田町７－１０ HEP Five １樓'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 34,
          img: require('@/assets/images/hfkansai/zh/kukuru_HEP_FIVE_tc.jpg'),
          title: ['大阪梅田章魚燒市場 kukuru HEP FIVE店 1000日元優惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北區角田町５−１５ HEP Five １樓'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 35,
          img: require('@/assets/images/hfkansai/zh/HEP_NAVIO_Umeda_tc.jpg'),
          title: ['大阪梅田章魚燒市場 会津屋 梅田HEP NAVIO店 1000日元優惠券','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒530-0017 大阪府大阪市北區角田町７－１０ HEP Five １樓'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 36,
          img: require('@/assets/images/hfkansai/zh/bistecca_tc.png'),
          title: ['bistecca IL FORNO 1000日元優惠券','<span>(2023/8/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒550-0015大阪府大阪市西區南堀江1-5-17 Canal terrace堀江 西棟1F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://bistecca-ilforno.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                { cid: 4, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        {
          id: 37,
          img: require('@/assets/images/hfkansai/zh/MIO_TC.jpg'),
          title: ['天王寺MIO 館內共通劵1500元優惠券','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒543-0055　大阪市天王寺區悲田院町10-39 "天王寺MIO 本館" 2F信息櫃台','※受理至2023年12月30日為止'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.tennoji-mio.co.jp/lang/tw/access/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['僅在天王寺 MIO（主樓和廣場樓）內的店鋪有效。'] },
                { cid: 2, text: ['部分商店不適用。使用此優惠券時，請詢問各商店的工作人員。 '] },
                { cid: 3, text: ['請用現金支付優惠券的差額。不找零。'] },
                { cid: 4, text: ['優惠券不可退款，也不可兌換現金。'] },
                { cid: 5, text: ['我們對優惠券的火災、失竊、丟失或損毀概不負責。'] },
                { cid: 6, text: ['部分商店的營業時間與此不同。營業時間如有變更，恕不另行通知。'] },
                { cid: 7, text: ['內容包含: 500日元份優惠券3張'] },
                ]
            }
          ]
        },
        {
          id: 43,
          img: require('@/assets/images/hfkansai/zh_tw/EDION_TC.jpg'),
          title: ['EDION難波観光組合套餐 1000元優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['大阪市中央區難波3丁目2番18號 EDION難波本店8F'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://namba.edion.com/en/'] }] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: [
                  '套餐包含:',
                  '<br>・忍者逃脫遊戲體驗(價值1000日幣)',
                  '<br>・免費行李寄放1日(價值800日幣)',
                  '<br>・化妝室 1小時使用 (價值300日幣) ※可以使用最新的家電和化妝品',
                  '<br>・充電站 30分鐘充電 (價值200日圓) ※iPhone和Android皆可利用'
                ] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
                { cid: 4, text: ['若有價差需現場支付差額。'] },
                { cid: 5, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
              ]
            }
          ]
        },
        {
          id: 44,
          img: require('@/assets/images/hfkansai/zh_tw/rentcar_tc.png'),
          title: ['車站租車2000日元的優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '（1）車站租車　新大阪營業所',
                '大阪市澱川區西中島5-16-1 JR新大阪站（南出口1樓）',
                '（2）車站租車　京都營業所',
                '京都市下京區東鹽小路町940JR京都站（車站大樓停車場1樓）',
                '（3）車站租車　新神戶營業所',
                '神戶市中央區加納町1-3-1 JR新神戶站（1樓中央大廳）',
                '（4）車站租車　姬路營業所',
                '兵庫縣姫路市南站前町125JR姫路站（中央檢票口向左，南出口向左，車站前停車場內）',
                ] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['（1）車站租車　新大阪營業所'],noColor:true },
                { cid: 2, text: ['https://www.ekiren.com/eigyosyo?eigyou=270200'] },
                { cid: 3, text: ['（2）車站租車　京都營業所'],noColor:true },
                { cid: 4, text: ['https://www.ekiren.com/eigyosyo?eigyou=260400'] },
                { cid: 5, text: ['（3）車站租車　新神戶營業所'],noColor:true },
                { cid: 6, text: ['https://www.ekiren.com/eigyosyo?eigyou=290200'] },
                { cid: 7, text: ['（4）車站租車　姬路營業所'],noColor:true },
                { cid: 8, text: ['https://www.ekiren.com/eigyosyo?eigyou=290800'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受2000日元的優惠券。'] },
                { cid: 2, text: ['只限當日使用・不能預約'] },
                { cid: 3, text: ['使用方法：在對象店鋪申請租車時，請出示愉快的關西樂享周遊券QR CODE、駕照、護照（日本國籍以外的人）。'] },
                { cid: 4, text: ['營業時間請事先確認官方網站。'] },
                { cid: 5, text: ['費用差額部分需要現場支付。'] },
                { cid: 6, text: ['不能指定車型。'] },
                { cid: 7, text: ['若車子預約狀況已滿可能無法使用。 請見諒。'] },
                { cid: 8, text: ['持有外國駕駛執照時於日本行駛時, 須持有有效的「國際駕駛執照」或「外國駕駛執照＋日文翻譯本」。來店前請務必先行確認。'] },
                { cid: 9, text: ['取車時我們提供加滿汽油的車給顧客, 請於還車時同樣的加滿汽油歸還車子。若無加滿汽油還車時,您將會被收取高於市場價格的汽油費用。(依照車子實際行駛距離計費。）'] },
                { cid: 10, text: ['使用本租車服務時將依照日本國內法以及租車租賃條款為基準。'] },
              ]
            }
          ]
        },
        {
          id: 45,
          img: require('@/assets/images/hfkansai/zh_tw/mingShi_tc.png'),
          title: ['明石壽司優惠券「技藝珍品」，2000日元優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['兵庫縣明石市大明石町1-1-23　（明石詢問處）'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.yokoso-akashi.jp/coupon.html'] },
                ] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含內容：優惠券面值2000日元，可用於享用明石壽司選單（1人份）。'] },
                { cid: 2, text: ['請事先查看官方網站以確認營業時間。'] },
                { cid: 3, text: ['因食材進貨狀況與時令季節，刊載餐奌的部分內容可能會有所更動，恕不另行通知，敬請見諒。'] },
              ]
            }
          ]
        },
        {
          id: 38,
          img: require('@/assets/images/hfkansai/zh/ADVENTURE_WORLD_TC.jpg'),
          title: ['動物遊樂園 冒險大世界 1日入場劵1000日元優惠券','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒649-2201 和歌山縣西牟婁郡白濱町堅田2399番地 (售票窗口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。(只限入場劵)'] },
                { cid: 2, text: ['營業時間請事先至官網確認。 '] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfkansai/zh/グループ 255.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/N-MxbCGVfIM',
          title: ['京都鐵道博物館','<span>(2023/8/18~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['京都府京都市下京區觀喜寺町 (博物館入口)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.kyotorailwaymuseum.jp/tc/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['限當日能多次入館，再入館時請出示兌換後的紀念入館券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
              ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfkansai/zh/グループ 256.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/3vEpVmem5dU',
          title: ['京都嵐山美食優惠劵+嵐電1日通票'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['京都府京都市右京區嵯峨天龍寺造路町20-2', '(嵐山站詢問處)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.keifuku.co.jp/cms/wp-content/uploads/cc0d12d1bb04c0b9865d0595a5d770a9-768x975.jpg'] },]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['電車時刻表請在官方網站上確認。'] },
                { cid: 2, text: ['美食優惠券對象店鋪請在官方網站上確認。'] },
                { cid: 3, text: ['剩下的美食優惠券不能兌現。'] },
              ]
            }
          ]
        },
        // 
        {
          id: 10,
          img: require('@/assets/images/hfkansai/sake_CH.jpg'),
          title: ['Japanese SAKE Festival in KYOTO ','「Sake Spring 」2023夏日祭典小酌入場券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['〒606-8343 京都市左京區岡崎成勝寺町9番地の1','(京都市勸業館MIYAKO MESSE 3Ｆ 第3展示場)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.sakesp.com/2023summer'] }] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['活動期間：2023/7/8~2023/7/9'] },
                { cid: 2, text: ['此為品鑒活動。 年齡未滿20歲的人不得參加。'] },
                { cid: 3, text: ['一旦離場，將無法再次入場。'] },
                { cid: 4, text: ['請提前查看官方網站以確認營業時間。'] },
              ]
            }
          ]
        },
        {
          id: 39,
          img: require('@/assets/images/hfkansai/zh_tw/TOEI_TC.jpg'),
          title: ['京都東映太秦映畫村 入場劵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['京都府京都市右京區太秦東蜂岡町10'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://global.toei-eigamura.com/zh/'] }] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['請提前查看官方網站以確認營業時間。'] },
                { cid: 2, text: ['各遊樂設施有使用限制，請事先至官網確認。'] },
              ]
            }
          ]
        },
        {
          id: 40,
          img: require('@/assets/images/hfkansai/zh_tw/pph_TC.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/nvht8Y_bbYk',
          title: ['琵琶湖大津王子飯店館內共通券 1,500日圓'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['滋賀縣大津市Niono濱4-7-7'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.princehotels.com/otsu/zh-hant/'] }] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['館內共通券的期限為發行後一周。過期無效。'] },
                { cid: 2, text: ['本券不能用於住宿費。'] },
                { cid: 3, text: ['差額請以現金支付。恕不找零。'] },
                { cid: 4, text: ['營業時間有可能無預告變更。另外，若是因為臨時停業、客滿等原因不能使用的情況也無法退款。'] },
              ]
            }
          ]
        },
        {
          id: 41,
          img: require('@/assets/images/hfkansai/zh_tw/PASSPORT_TC.jpg'),
          title: ['長濱散步PASSPORT (2日)'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['長浜市北船町1-5（JR長浜站內）'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://kitabiwako.jp/cn/travel_guide'] }] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['PASSPORT的使用開始日(含)起2天有效。　※並非48小時'] },
                { cid: 2, text: ['一個設施只能入場一次，不可以重複使用。'] },
                { cid: 3, text: ['請事先確認各店鋪、設施的營業時間和休息日。'] },
                { cid: 4, text: ['各設施的特別展會有需要另外追加費用。'] },
              ]
            }
          ]
        },
        {
          id: 42,
          img: require('@/assets/images/hfkansai/zh_tw/yxt_TC.jpg'),
          title: ['一心堂 1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['奈良縣奈良市上三條町3番地之9'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://issindo-nara.jp/'] }] 
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                { cid: 3, text: ['若有價差需現場支付差額。'] },
              ]
            }
          ]
        },
        {
          id: 29,
          img: require('@/assets/images/hfkansai/zh/Yoshitaya_tc.jpg'),
          title: ['吉高屋 龜印美肌香皂 兌換券','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒651-1401　兵庫縣神戶市北區有馬町259'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.yoshitakaya.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['圖片僅供參考，實品請以實物為主。'] },
                { cid: 2, text: ['營業時間請事先至官網確認。'] },
                ]
            }
          ]
        },
        {
          id: 27,
          img: require('@/assets/images/hfkansai/zh/carbonated_tc.jpg'),
          title: ['元祖三津森本舖 炭酸煎餅罐裝30片入','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒651-1401　兵庫縣神戶市北區有馬町290-1'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['http://tansan.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['店舗有人群擁擠的可能性。屆時，需要與一般顧客一同等待。此票券並無優先權。'] },
                { cid: 2, text: ['圖片僅供參考，實品請以實物為主。'] },
                { cid: 3, text: ['營業時間請事先至官網確認。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 11,
          img: require('@/assets/images/hfkansai/zh/グループ 257.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/q101QK5r8Sc',
          title: ['神戶布引香草園入場劵及纜車乘車劵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['兵庫縣神戶市中央區北野町1-4-3（山麓站4樓諮詢台）'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.kobeherb.com/tw/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['晚間開放期間（17:00開始），僅限眺望區允許進入。'] },
                { cid: 2, text: ['全年無休（冬季因年度檢修將停運/閉園約1個月。）'] },
                { cid: 3, text: ['如遇大風、雷電，有可能停運或暫停開放。'] },
              ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfkansai/zh/グループ 258.png'),
          title: ['神戶浪漫協奏曲號1000日元優惠劵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['神戶市中央區東川崎町1-6-1 神戶Harborland umie 1F', '(Concerto乘船處)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://thekobecruise.com/concerto/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行日程請在官方網站上確認。'] },
                { cid: 2, text: ['滿座的話可能會無法乘船。'] },
                { cid: 3, text: ['費用差額需要在現場支付。'] },
              ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfkansai/zh/グループ 259.png'),
          title: ['MOSAIC馬賽克摩天輪'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['神戶市中央區東川崎町1-6-1 神戶Harborland umie (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://umie.jp/features/mosaickanransya'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['因天氣不佳或設施維護會臨時停業。'] },
              ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfkansai/zh/グループ 260.png'),
          title: ['觀音屋 1000日圓優惠券'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['神户市中央区东川崎町1-6-1 神户Harborland umie 1F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/76'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可使用1000日圓優惠券。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
              ]
            }
          ]
        },
        {
          id: 15,
          img: require('@/assets/images/hfkansai/zh/グループ 248.png'),
          title: ['Tonton Tonkatsu 1000日圓', '優惠券 / 指定套餐'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['神戶市中央區東川崎町1-6-1 神戶Harborland umie 2F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/93'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元優惠券/套餐。'] },
                { cid: 2, text: ['若有價差需現場支付差額。'] },
                { cid: 3, text: ['有可能會因為店家客滿而無法使用的狀況，請見諒。'] }
              ]
            }
          ]
        },
        {
          id: 16,
          img: require('@/assets/images/hfkansai/zh/グループ 247.png'),
          title: ['Frantz 甜點優惠劵'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['神戶市中央區東川崎町1-6-1 神戶Harborland umie 2F (Mosaic)'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/98'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: false, //是否启用ul的数字前缀 //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['3種甜點中任選1種。'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFKansai&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.xiao_btn_img{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 16px;
  a{
    height: 32px;
    width: 32%;
    // flex: 1;
    // text-align: center;
  }
  img{
    // flex: 1;
    // width: auto;
    width: 90%;
    max-width: 144px;
    height: auto;
    // height: 100%;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
.dltAbq:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>